import { computed, Injectable, Signal, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { langugaes, langugaesResponse } from '../models/common';

@Injectable({
  providedIn: 'root',
})
export class InitialService {
  script = document.createElement('script');
  private _languages = signal<langugaes[]>([]);
  constructor(private _authService: AuthService) {}

  async setUpGoogleScript(): Promise<void> {
    const language = localStorage.getItem('language') || 'en';

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const head = document.getElementsByTagName('head')[0];

        if (!document.getElementById('testScriptName')) {
          this.script.type = 'text/javascript';
          
          this.script.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.GOOGLE_API_KEY}&libraries=places&language=${language}`;
          this.script.id = 'testScriptName';
          this.script.async = true;
          this.script.defer = true;  

          this.script.onload = () => {
            const googleMaps = window['google'] as typeof google;  // Type assertion
            if (googleMaps && googleMaps.maps) {
              resolve();
            } else {
              reject('Google Maps API failed to load');
            }
          };

          this.script.onerror = (error) => {
            console.error('Error loading Google Maps script:', error);
            reject('Google Maps API failed to load');
          };

          head.appendChild(this.script);
        }
      }, 100);
    });
  }

  getLanguages(): void {
    this._authService.languages().subscribe((res: langugaesResponse) => {
      this._languages.set(res.list);
    });
  }

  get languages(): Signal<langugaes[]> {
    return computed(() => this._languages());
  }
}
